<template>
  <div class="elec-container">
    <div class="page-outter">
      <div align="center">
        <img
          class="elec-title"
          v-lazy="require('../../../assets/images/title7.png')"
        />
        <div class="elec-dd">
          <ul>
            <li
              v-for="(item,index) in tds"
              :key="index"
            >
              <div class="pp-dd">
                <img
                  class="m1"
                  v-lazy="item.icon"
                />
                <span
                  class="s1"
                  v-html="item.name"
                ></span>
              </div>
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "electronicData",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/qt-1.png"),
          name: `资料庞杂，整理邮寄<br>浪费人力物力`,
        },
        {
          icon: require("../../../assets/images/qt-2.png"),
          name: `线下邮寄往返时间长<br>时效性差`,
        },
        {
          icon: require("../../../assets/images/qt-3.png"),
          name: `纸质资料，异地交换<br>监管难度大`,
        },
        {
          icon: require("../../../assets/images/qt-4.png"),
          name: `存档保管不易<br>难以快速查询`,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.elec-container {
  width: 100%;
  background: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.elec-title {
  width: 7rem;
  margin-top: 2.4rem;
}
.elec-dd {
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-left: -0.4rem;
    li {
      float: left;
      margin-left: 0.4rem;
      margin-top: 1.4rem;
      .pp-dd {
        width: 5.7rem;
      }
      .m1 {
        width: 100%;
      }
      .s1 {
        font-size: 0.36rem;
        display: block;
        color: #6aa5ff;
        line-height: 1.5;
      }
    }
  }
}
</style>